var animateImg = 'https://staticcdn.buzud.com/home/loading.webp';
var animateGifImg = 'https://staticcdn.buzud.com/home/loading.gif';
var animateLightVideo = 'https://staticcdn.buzud.com/home/loading-light.mp4';
var animateDarkVideo = 'https://staticcdn.buzud.com/home/loading-dark.mp4';
// var testVideo = 'https://staticcdn.buzud.com/home/empty.mp4';

var preloadDom,
    animateDom;
// Check for WebP support

// Check for animated WebP support
function supportsAnimatedWebP() {
    const elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get animated WebP representation
        return elem.toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

/*function checkInlinePlaybackAllowed() {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        video.setAttribute('playsinline', true);
        video.setAttribute('webkit-playsinline', true);
        video.muted = true;
        video.src = testVideo;

        // Try to play the video
        const playPromise = video.play();

        if (playPromise !== undefined) {
            playPromise.then(() => {
                // Video played inline
                resolve(true);
            }).catch((e) => {
                console.log('1', e);
                // Inline playback not allowed
                resolve(false);
            }).finally(() => {
                video.remove();
            });
        } else {
            // Older browsers might not return a promise from play()
            setTimeout(() => {
                if (!video.paused) {
                    resolve(true);
                } else {
                    resolve(false);
                }
                video.remove();
            }, 0);
        }
    });
}*/

var toastDiv = null;
var boxDiv = null;

var isDarkMode = $('body').hasClass('dark');

if (supportsAnimatedWebP()) {
    preloadDom = '<link rel="preload" href="' + animateImg + '" as="image"/>';
    animateDom = '<img src="' + animateImg + '"  alt="Loading"/>';
} else if (/ iPhone OS /.test(navigator.userAgent) && Date.now() < 1734192000000) {
    // iPhone and before 2024-12-15
    // iPhone WKWebview not support inline video by default, use gif instead
    // https://github.com/prebid/Prebid.js/issues/11438
    preloadDom = '<link rel="preload" href="' + animateGifImg + '" as="image"/>';
    animateDom = '<img src="' + animateGifImg + '"  alt="Loading"/>';
} else {
    if (isDarkMode) {
        preloadDom = '<link rel="preload" href="' + animateDarkVideo + '" as="video"/>';
        animateDom = '<video src="' + animateDarkVideo + '" autoplay loop muted playsinline></video>';
    } else {
        preloadDom = '<link rel="preload" href="' + animateLightVideo + '" as="video"/>';
        animateDom = '<video src="' + animateLightVideo + '" autoplay loop muted playsinline></video>';
    }
}


$('head').append(preloadDom);
boxDiv = $('<div></div>');
boxDiv.css({
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'top': 0,
    'left': 0,
    'zIndex': 10000,
    'opacity': 1,
    'display': 'none',
    background: 'rgba(0,0,0,0.4)'
});

toastDiv = $('<div></div>');
animateDom = $(animateDom);
toastDiv.append(animateDom);
toastDiv.append('<span>Loading ...</span>');
toastDiv.css({
    'position': 'absolute',
    'top': '50%',
    'left': '50%',
    'padding': '3.5rem 0 2.5rem',
    width: '18.4375rem',
    // height: '13rem',
    'transform': 'translate(-50%,-50%)',
    'backgroundColor': isDarkMode ? '#212629' : '#fff',
    'borderRadius': '0.75rem',
    'fontSize': '1.0625rem',
});
toastDiv.find('img,video').css({
    margin: '0 auto',
    display: 'block',
    width: '5rem',
    height: '5rem',
});
toastDiv.find('span').css({
    display: 'block',
    margin: '0.5rem auto',
    padding: '0 1rem',
    textAlign: 'center',
    fontSize: '1.0625rem',
    lineHeight: '1.5rem',
    color: isDarkMode ? '#fff' : '#0E0F10',
});
boxDiv.append(toastDiv);

/*checkInlinePlaybackAllowed().then((allowInline) => {


});*/


var toastTimeout = null;

module.exports = {
    show: function (text, timeout, callback) {
        
        var $this = this;
        $this.hide();
        var content = toastDiv.find('span');
        content.html('Loading...');
        animateDom.show();
        if (typeof text === 'object') {
            switch (text.type) {
                case 'html':
                    content.html(text.content);
                    break;
                case 'text':
                    content.text(text.content);
                    break;
            }
            if (text.hideLoading) {
                console.log('hide hideLoading')
                animateDom.hide();
            }
        } else {
            content.text(text);
        }

        $('body').append(boxDiv);
        boxDiv.css({
            'opacity': 0,
            'display': 'block'
        }).animate({opacity: 1}, 300, 'swing', function () {
            if (timeout > 0) {
                toastTimeout = setTimeout(function () {
                    boxDiv.animate({opacity: 0}, 300, 'swing', function () {
                        $this.hide();
                        typeof callback === 'function' && callback();
                    })
                }, timeout);
            }
        });

    },
    hide: function () {
        if (toastTimeout !== null) {
            clearTimeout(toastTimeout);
        }
        boxDiv.stop().css({
            opacity: 1,
            display: 'none'
        });
        boxDiv.remove();
    },
    getLoadingDom: function () {
        return animateDom;
    }
};